exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-directions-architectual-index-tsx": () => import("./../../../src/pages/directions/architectual/index.tsx" /* webpackChunkName: "component---src-pages-directions-architectual-index-tsx" */),
  "component---src-pages-directions-index-tsx": () => import("./../../../src/pages/directions/index.tsx" /* webpackChunkName: "component---src-pages-directions-index-tsx" */),
  "component---src-pages-directions-svet-index-tsx": () => import("./../../../src/pages/directions/svet/index.tsx" /* webpackChunkName: "component---src-pages-directions-svet-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inform-index-tsx": () => import("./../../../src/pages/inform/index.tsx" /* webpackChunkName: "component---src-pages-inform-index-tsx" */),
  "component---src-pages-policy-index-tsx": () => import("./../../../src/pages/policy/index.tsx" /* webpackChunkName: "component---src-pages-policy-index-tsx" */),
  "component---src-pages-profession-lighting-designer-index-tsx": () => import("./../../../src/pages/profession-lighting-designer/index.tsx" /* webpackChunkName: "component---src-pages-profession-lighting-designer-index-tsx" */),
  "component---src-pages-sitemap-index-tsx": () => import("./../../../src/pages/sitemap/index.tsx" /* webpackChunkName: "component---src-pages-sitemap-index-tsx" */),
  "component---src-pages-termofuse-index-tsx": () => import("./../../../src/pages/termofuse/index.tsx" /* webpackChunkName: "component---src-pages-termofuse-index-tsx" */)
}

