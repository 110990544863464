// extracted by mini-css-extract-plugin
export var center = "m_W";
export var content = "m_T";
export var fromLeft = "m_X";
export var fromLeftRev = "m_Z";
export var isClosing = "m_R";
export var left = "m_Q";
export var modal = "m_P";
export var opacity = "m_V";
export var opacityRev = "m_Y";
export var opened = "m_K";
export var overlay = "m_S";