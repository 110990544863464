// extracted by mini-css-extract-plugin
export var attention = "h_y";
export var button = "h_q";
export var disabled = "h_B";
export var icon = "h_s";
export var primary = "h_v";
export var save = "h_z";
export var secondary = "h_w";
export var text = "h_t";
export var white = "h_x";
export var withoutText = "h_r";